import axios from "axios";

export const reportService = {
    async accommodationReport(params) {
        return (await axios.get(`/report-accommodation/`, {params})).data
    },
    async busReport(params) {
        return (await axios.get(`/report-bus/`, {params})).data
    },
    async tourReport(params) {
        return (await axios.get(`/report-tour/`, {params})).data
    },
    async trainReport(params) {
        return (await axios.get(`/report-railway/`, {params})).data
    },
    async salesReport(params) {
        return (await axios.get(`/report-sales-channel/`, {params})).data
    },
    async agentReport(params) {
        return (await axios.get(`/report-counter-agent/`, {params})).data
    },
    async getAgentFilter(params, name){
        return (await axios.get(name, { params })).data
    },
    async getAdminReportCompanies(params){
        return (await axios.get('/report-admin/companies/', { params })).data
    },
    async getAdminReportHotels(params){
        return (await axios.get('/report-admin/hotel/', { params })).data
    },
}